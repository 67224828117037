<template>
  <div class="pdf-wrap">
    <div
      class="full-loading"
      v-if="loading"
    >
      <div class="loading-sty1">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div
      class="export-pdf"
      @click="downPdf()"
    >导出PDF</div>
    <div id="pdf-dom">
      <div class="page home-page">
        <div class="title">
          <div class="project-name">{{projectName}}</div>
          <div class="vice-plan">{{from === '1' ? '品牌落位计划' : '铺位招商可视化'}}</div>
          <div
            class="file-name"
            v-if="from === '1'"
          >{{projectHeadData.decisionName}}</div>
        </div>
        <div class="time">{{today}}</div>
      </div>
      <div class="page data-page">
        <div class="title">{{from === '1' ? '落位计划总览' : '招商过程概览'}}</div>
        <div
          class="berth-info"
          v-if="from === '1'"
        >
          <div class="info-item">
            <div class="block-div"></div>
            <div class="number">{{projectHeadData.planBrandNum}}</div>
            <div class="info-title">规划品牌数量</div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">覆盖业态</div>
              <div class="tips">{{projectHeadData.industryNum}}</div>
            </div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">S级品牌</div>
              <div class="tips">{{projectHeadData.sightceBrandNum}}</div>
            </div>
          </div>
          <div class="info-item">
            <div class="block-div"></div>
            <div class="number">{{projectHeadData.alreadyBerthNum}}</div>
            <div class="info-title">已落位铺位</div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">主力店</div>
              <div class="tips">{{projectHeadData.mainStoreNum}}</div>
            </div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">次主力店</div>
              <div class="tips">{{projectHeadData.minorNum}}</div>
            </div>
          </div>
          <div class="info-item">
            <div class="block-div"></div>
            <div class="number">{{projectHeadData.alreadybuildArea}}m²</div>
            <div class="info-title">已落位面积(㎡)</div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">未规划面积</div>
              <div class="tips">{{projectHeadData.notPlanArea}}m²</div>
            </div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">规划进度</div>
              <div class="tips">{{projectHeadData.planProgress}}%</div>
            </div>
          </div>
        </div>
        <div
          class="berth-info"
          v-if="from === '2'"
        >
          <div class="info-item">
            <div class="block-div"></div>
            <div class="number">{{processOverview.berthOk}}%</div>
            <div class="info-title">招商完成率</div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">未签约面积</div>
              <div class="tips">{{processOverview.notSignedArea}}m²</div>
            </div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">已签约面积</div>
              <div class="tips">{{processOverview.signedArea}}m²</div>
            </div>
          </div>
          <div class="info-item">
            <div class="block-div"></div>
            <div class="number">{{processOverview.rentBerthNum}}</div>
            <div class="info-title">已租铺位</div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">未签约铺位</div>
              <div class="tips">{{processOverview.notSignedBerthNum}}</div>
            </div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div class="text-bold">在招商铺位</div>
              <div class="tips">{{processOverview.taskBerthNum}}</div>
            </div>
          </div>
          <div class="info-item">
            <div class="block-div"></div>
            <div class="number">{{processOverview.followBrandNum}}</div>
            <div class="info-title">跟进中品牌</div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div
                class="text-bold"
                style="width: 160px;"
              >已签约品牌</div>
              <div class="tips">{{processOverview.signedbrandNum}}</div>
            </div>
            <div class="text">
              <div class="circle"><span></span></div>
              <div
                class="text-bold"
                style="width: 160px;"
              >待跟进品牌</div>
              <div class="tips">{{processOverview.toBeFollowBrandNum}}</div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in floorLit"
        :key="item.id"
        class="floor-page"
      >
        <div class="drop-page">
          <div class="white-content">
            <div class="info">
              <div class="title">
                <div class="block"></div>
                <div class="name">{{item.building}} {{item.floor}} {{from === '1' ? '品牌落位图' : '招商过程可视化'}}</div>
              </div>
              <div class="berth-num">铺位数量：{{item.berthNum}}</div>
              <div class="berth-area">
                <div class="build-area">建筑面积：{{item.buildArea}}㎡</div>
                <div class="use-area">使用面积：{{item.useArea}}㎡</div>
                <div
                  class="use-area"
                  style="margin-left: 30px;"
                  v-if="from === '2'"
                >招商完成率：{{item.investmentRatio}}%</div>
              </div>
            </div>
            <div
              class="page-map"
              @wheel.stop=""
            >
              <iframe
                id="map"
                @wheel.stop=""
                :src="iframeUrl + '&floorIdx=' + index"
                frameborder="0"
                width="100%"
                height="960px"
              ></iframe>
            </div>
            <div
              class="positon-a plan-icon-list"
              v-if="from === '1'"
            >
              <template v-for="item in iconList">
                <img
                  :key="item.industry"
                  :src="item.img"
                  alt=""
                >{{item.industry}}
              </template>
            </div>
            <div
              class="positon-a map-font-color"
              v-if="from === '2'"
            >
              <div
                class="positon-a"
                v-if="picType === '1'"
              >
                <span><i style="background: rgba(161, 105, 40, 1);"></i>待跟进</span>
                <span><i style="background: rgba(52, 70, 180, 1);"></i>已联系</span>
                <span><i style="background: rgba(14, 153, 151, 1);"></i>已看场</span>
                <span><i style="background: rgba(148, 140, 49, 1);"></i>已报价</span>
                <span><i style="background: rgba(143, 63, 22, 1);"></i>已预定</span>
                <span><i style="background: rgba(9, 151, 70, 1);"></i>已签约</span>
                <span><i style="background: rgba(81, 45, 101, 1);"></i>待招商</span>
                <span><i style="background: rgba(153, 153, 153, 1);"></i>已搁置/无意向</span>
              </div>
              <div
                style="margin-left: -190px;"
                v-if="picType === '2'"
              >
                <span><i style="background: rgba(156, 172, 101, 1);"></i>继续招商中</span>
                <span><i style="background: rgba(9, 151, 70, 1);"></i>已签约</span>
                <span><i style="background: rgba(136, 92, 178, 1);"></i>已预定</span>
                <span><i style="background: rgba(32, 214, 254, 1);"></i>招商中</span>
                <span v-if="domainIsWinshang"><i style="background: rgba(81, 45, 101, 1);"></i>待招商</span>
                <span v-else><i style="background: #dd5e1d;"></i>待招商</span>
              </div>
            </div>
          </div>
        </div>
        <div class="floor-page">
          <div class="white-content">
            <div class="title">
              <div class="block"></div>
              <div class="text">{{item.building}}{{item.floor}}{{from === '1' ? '落位品牌统计' : '铺位招商统计'}}</div>
            </div>
            <div class="table">
              <table v-if="from === '1'">
                <thead>
                  <tr>
                    <th width='80'></th>
                    <th>业态</th>
                    <th>建筑面积</th>
                    <th>面积占比</th>
                    <th>铺位数</th>
                    <th>品牌级次</th>
                    <th>品牌数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item2, index2) in item.berthRnetInfoList"
                    :key="index2"
                  >
                    <td></td>
                    <td>{{item2.industry}}</td>
                    <td>{{item2.buildArea}}</td>
                    <td>{{item2.areaProportion}}%</td>
                    <td>{{item2.berthNum}}</td>
                    <td>
                      <div
                        v-for="(item3, index3) in item2.levelAndNumList"
                        :key="index3"
                      >{{item3.brandLevel}}</div>
                      <div v-if="item2.levelAndNumList && item2.levelAndNumList.length < 1">-</div>
                      <div v-if="item2.levelAndNumList && item2.levelAndNumList.length < 2">-</div>
                      <div v-if="item2.levelAndNumList && item2.levelAndNumList.length < 2">-</div>
                    </td>
                    <td>
                      <div
                        v-for="(item3, index3) in item2.levelAndNumList"
                        :key="index3"
                      >{{item3.brandNum}}</div>
                      <div v-if="item2.levelAndNumList && item2.levelAndNumList.length < 1">-</div>
                      <div v-if="item2.levelAndNumList && item2.levelAndNumList.length < 2">-</div>
                      <div v-if="item2.levelAndNumList && item2.levelAndNumList.length < 2">-</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table v-if="from === '2'">
                <thead>
                  <tr>
                    <th width='80'></th>
                    <th>铺位招商状态</th>
                    <th>建筑面积</th>
                    <th>铺位数</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item2, index2) in item.berthStatusList"
                    :key="index2"
                    style="height: 47px;"
                  >
                    <td></td>
                    <td>{{item2.status}}</td>
                    <td>{{item2.buildArea}}㎡</td>
                    <td>{{item2.berthNum}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: false,
      picType: 0,
      buildCode: '',
      iframeUrl: '',
      projectName: '',
      decisionId: 1,
      projectHeadData: {},
      from: 1, // 1是落位计划管理 2是招商可视管理
      iconList: [],
      floorLit: [],
      pdfName: '111111.pdf',
      today: dayjs().format('YYYY-MM-DD'),
      processOverview: {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
    domainIsWinshang: function () {
      return document.domain.indexOf('winshangdata.com') > -1
    }
  },
  mounted () {
    // console.log(this.user)
    this.projectId = this.$route.query.id
    this.from = this.$route.query.from
    this.picType = this.$route.query.picType
    const args = this.$route.query.picType.split('_') || []
    if (args.length > 0) {
      this.picType = args[0]
      this.buildCode = args[1] || this.$route.query.buildCode
    } else {
      this.buildCode = this.$route.query.buildCode
    }
    console.log(this.buildCode)
    if (this.from === '1') {
      this.decisionId = this.$route.query.decisionId
      this.getPdfHeaderData()
    } else {
      this.getBerthTaskOverviewFunc()
    }
    this.getPdfMap()
    // this.getGdBuildingFunc()
    if (this.buildCode) {
      this.reloadMap()
    }
    this.getIndustryIconFunc()
  },
  methods: {
    getPdfHeaderData () {
      this.axios.post(api.planOverview,
        { projectId: this.projectId, decisionId: this.decisionId, buildCode: this.buildCode })
        .then((res) => {
          this.projectName = res.data.data.projectName
          this.projectHeadData = res.data.data
        })
    },
    getBerthTaskOverviewFunc () {
      this.axios.post(api.getBerthTaskOverview, { projectId: this.projectId, buildCode: this.buildCode })
        .then((res) => {
          this.projectName = res.data.data.projectName
          this.processOverview = res.data.data
        })
    },
    getPdfMap () {
      const data = {}
      data.projectId = this.projectId
      data.buildCode = this.buildCode
      if (this.from === '1') {
        data.decisionId = this.decisionId
      }
      this.axios.post(api.exportRentPlan, data)
        .then((res) => {
          this.floorLit = res.data.data.reverse()
        })
    },
    downPdf () {
      this.loading = true
      document.body.style.overflowY = 'hidden'
      this.axios.get(api.exportReportCtr + `?domain=${document.domain}&projectId=${this.projectId}&decisionId=${this.decisionId}&from=${this.from}&picType=${this.picType + '_' + this.buildCode}&pagename=landingPlanPdf`, {
        headers: {
          token: this.token
        },
        responseType: 'blob'
      })
        .then((res) => {
          const _res = res.data
          const blob = new Blob([_res], { type: 'application/pdf' })
          // FileReader主要用于将文件内容读入内存
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          // onload当读取操作成功完成时调用
          reader.onload = () => {
            const a = document.createElement('a')
            // 获取文件名fileName
            const fileName = this.from === 1 ? this.projectName + '铺位招商可视化' : this.projectName + '品牌落位计划'
            a.download = fileName
            a.href = URL.createObjectURL(blob)
            document.body.appendChild(a)
            a.click()
            setTimeout(() => {
              URL.revokeObjectURL(a.href) // 释放URL 对象
              document.body.removeChild(a)
            }, 1000)
          }
          this.loading = false
          document.body.style.overflowY = 'auto'
        })
    },
    getGdBuildingFunc () {
      this.axios.post(api.getGdbuilding, { projectId: this.projectId })
        .then((res) => {
          // this.buildingId = res.data.data[0].buildingCode
          if (this.buildingId) {
            this.reloadMap()
          }
        })
    },
    getIndustryIconFunc () {
      this.axios.post(api.getIndustryIcon)
        .then((res) => {
          this.iconList = res.data.data
        })
    },
    reloadMap () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://localhost:8001'
      } else if (window.location.href.indexOf('http://') > -1) {
        temUrl = 'http://' + document.domain + '/map'
      } else {
        temUrl = 'https://' + document.domain + '/map'
      }
      this.iframeUrl = temUrl + '/pdf.html?token=' +
        this.token +
        '&projectId=' + this.projectId +
        '&picType=' + this.picType +
        '&buildingId=' + this.buildCode +
        '&decisionId=' + this.decisionId
    }
  }
}
</script>

<style lang="stylus" scoped>
.loading-box
  width 100%
  height 100%
  position fixed
  z-index 999
.page
  height 1084px
  // margin-bottom 10px
.pdf-wrap
  background-color #fff
  height 100%
  .home-page
    width 100%
    // height 100%
    background url('~@/assets/images/pdf/pdf-home.png') no-repeat
    background-size 100% 100%
    position relative
    .title
      position absolute
      left 35%
      top 30%
      color #3265fb
      font-weight bold
      .project-name
        font-size 60px
      .vice-plan
        font-size 60px
    .time
      position absolute
      right 10%
      bottom 80px
      font-size 22px
      color #3265fb
  .data-page
    width 100%
    background url('~@/assets/images/pdf/pdf-bg.png') no-repeat
    background-size 100% 50%
    position relative
    .title
      position absolute
      left 10%
      top 70px
      font-size 60px
      color #fff
      font-weight bold
    .berth-info
      display flex
      height 1084px
      justify-content center
      align-items center
      .info-item
        width 360px
        height 430px
        background-color #fff
        border-radius 2px
        box-shadow 0 5px 20px 0 rgba(0, 0, 0, 0.2)
        margin 0 5%
        position relative
        .block-div
          width 32px
          height 32px
          background-color #3265fb
          margin auto
          margin-top 24px
          position relative
          &:after
            content ''
            width 16px
            height 16px
            background-color #fff
            position absolute
            left 0
            top 0
        .number
          font-size 60px
          font-weight bold
          text-align center
          margin-top 48px
          color #3265fb
          margin-bottom 120px
        .info-title
          position absolute
          left -18px
          top 200px
          width 396px
          height 70px
          background-color #3265fb
          font-size 34px
          line-height 70px
          text-align center
          color #fff
          box-shadow 0 5px 20px 0 rgba(0, 0, 0, 0.2)
        .text
          color #333
          font-size 22px
          display flex
          margin-bottom 20px
          .circle
            padding-left 20px
            span
              display inline-block
              width 5px
              height 5px
              background-color #333
              border-radius 50%
              vertical-align middle
          .text-bold
            font-size 22px
            font-weight bold
            color #333
            margin 0 13px
            width 120px
            // flex-shrink 0
          .tips
            font-size 22px
            color #333
.floor-page
  .white-content
    width 100%
    height 100%
    background-color #fff
    position relative
  .drop-page
    width 100%
    height 1084px
    background url('~@/assets/images/pdf/pdf-bg.png') no-repeat
    background-size 100% 100%
    // margin-bottom 10px
    padding 60px
    .info
      position absolute
      left 40px
      top 40px
      .title
        display flex
        align-items center
        .block
          width 32px
          height 32px
          background-color #3b65fc
          margin-right 10px
        .name
          font-size 32px
          color #333
          font-weight bold
      .berth-num
        color #666
        font-size 26px
      .berth-area
        display flex
        font-size 26px
        color #666
        .build-area
          margin-right 30px
  .floor-page
    width 100%
    height 1084px
    background url('~@/assets/images/pdf/pdf-bg.png') no-repeat
    background-size 100% 100%
    // margin-bottom 10px
    padding 60px
    .white-content
      padding 44px 50px
      .title
        display flex
        align-items center
        .block
          width 32px
          height 32px
          background-color #3b65fc
        .text
          font-size 32px
          margin-left 10px
          color #333
          font-weight bold
      .table
        width 100%
        table
          width 100%
          thead
            tr
              th
                text-align center
                background-color #7d96d1
                color #fff
                font-size 16px
                border 2px solid #fff
                height 28px
          tbody
            tr
              border-bottom 3px solid #fff
              &:nth-child(odd)
                background-color #d3dfee
                div
                  &:nth-child(odd)
                    background-color #d3dfee
                  &:nth-child(even)
                    background-color #eaeff7
              &:nth-child(even)
                background-color #eaeff7
                div
                  &:nth-child(odd)
                    background-color #eaeff7
                  &:nth-child(even)
                    background-color #d3dfee
              td
                text-align center
                border 2px solid #fff
                color #333
                font-size 14px
                font-weight bold
                &:first-child
                  background-color #7d96d1
                  border-bottom 2px solid #7d96d1
                  position relative
                  &:after
                    content ''
                    position absolute
                    bottom -3px
                    left 0
                    width 80px
                    height 3px
                    background-color #7d96d1
                div
                  height 25px
                  line-height 25px
                  border-right 1px solid #fff
                  border-bottom 2px solid #fff
                  &:last-child
                    border-bottom none
.export-pdf
  position fixed
  right 20px
  top 20px
  width 120px
  height 36px
  background-color #3b65fc
  color #fff
  text-align center
  line-height 36px
  font-size 20px
  border-radius 3px
  z-index 111
  cursor pointer
.plan-icon-list
  font-size 14px
  color #333
  font-weight 500
  text-align center
  position relative
  margin-top -40px
  img
    margin-right 5px
    margin-left 10px
    width 18px
    height 18px
    vertical-align middle
.map-font-color
  position absolute
  bottom 10px
  left 50%
  text-align center
  color #333
  font-size 14px
  font-weight 500
  span
    margin 0 8px
    i
      display inline-block
      width 12px
      height 12px
      border-radius 6px
      vertical-align middle
      margin-right 4px
</style>
